<!-- 产品参数、设备参数、设备属性共用弹窗 -->
<template>
    <a-modal :visible="visible" :title="title" @ok="ok" @cancel="emit('cancel')" :confirm-loading="loading" width="500px">
        <a-form ref="formRef" :model="formState" :rules="rulesRef" v-bind="formLayout" style="width: 408px;">
            <a-form-item label="功能类别" required>
                <a-input :value="activeTab.label" disabled />
            </a-form-item>
            <a-form-item name="functionType" label="类型">
                <a-select v-model:value="formState.functionType" placeholder="请选择" :disabled="isEditing">
                    <a-select-option v-for="item in functionType" :key="item.id" :value="item.value">{{ item.label
                    }}</a-select-option>
                </a-select>
            </a-form-item>
            <a-form-item name="identifier" label="功能标识">
                <a-input v-model:value="formState.identifier" placeholder="请输入" :disabled="isEditing"></a-input>
            </a-form-item>
            <a-form-item name="functionName" label="功能名称">
                <a-input v-model:value="formState.functionName" placeholder="请输入"></a-input>
            </a-form-item>
            <a-form-item name="dataType" label="数据类型">
                <a-select v-model:value="formState.dataType" @change="changeDataType" placeholder="请选择"
                    :disabled="isEditing">
                    <a-select-option v-for="item in dataType" :key="item.id" :value="item.value">{{ item.label
                    }}</a-select-option>
                </a-select>
            </a-form-item>

            <!-- 根据数据类型 展示不同数据 -->
            <a-form-item v-if="formState.dataType == '01'" name="stringLimit" label="字符数量"
                :rules="{ required: true, message: '请输入字符数量' }">
                <a-input-number v-model:value="formState.stringLimit" :min="0" />
            </a-form-item>
            <template v-else-if="formState.dataType == '02' || formState.dataType == '03'">
                <a-space v-for="(desc, index) in formState.valueDescription" :key="desc.id">
                    <a-form-item :name="['valueDescription', index, 'first']" label="数值范围"
                        :rules="{ required: true, message: '请输入最小值' }">
                        <a-input-number v-model:value="desc.first" placeholder="最小值" :max="desc.last - 1"
                            :precision="formState.dataType == '02' ? 0 : undefined" />
                    </a-form-item>
                    <div class="split-line">—</div>
                    <a-form-item :name="['valueDescription', index, 'last']" :rules="{ required: true, message: '请输入最大值' }">
                        <a-input-number v-model:value="desc.last" placeholder="最大值" :min="desc.first + 1"
                            :precision="formState.dataType == '02' ? 0 : undefined" />
                    </a-form-item>
                </a-space>
                <a-form-item name="unit" label="单位" :rules="{ required: true, message: '请选择单位' }">
                    <a-select v-model:value="formState.unit" placeholder="请选择单位">
                        <a-select-option v-for="item in unit" :key="item.id" :value="item.value">{{ item.label
                        }} - {{ item.value }}</a-select-option>
                    </a-select>
                </a-form-item>
            </template>
            <template v-else-if="formState.dataType == '04'">
                <a-space v-for="(desc, index) in formState.valueDescription" :key="desc.id">
                    <a-form-item :class="index === 0 ? '' : 'no-default-split'" :name="['valueDescription', index, 'first']"
                        :label="index === 0 ? '布尔值' : ' '" :rules="{ required: true, message: '请输入' }">
                        <a-input :value="desc.first" disabled />
                    </a-form-item>
                    <div class="split-line" style="width: 32px;">:</div>
                    <a-form-item :name="['valueDescription', index, 'last']" :rules="{ required: true, message: '请输入' }">
                        <a-input v-model:value="desc.last" placeholder="请输入" />
                    </a-form-item>
                </a-space>
            </template>
            <template v-else-if="formState.dataType == '05'">
                <div class="enums" v-for="(desc, index) in formState.valueDescription" :key="desc.id" style="width: 448px;">
                    <a-form-item :class="index === 0 ? '' : 'no-default-split'" :name="['valueDescription', index, 'first']"
                        style="width: 232px;" :label="index === 0 ? '枚举值' : ' '"
                        :rules="{ required: true, message: '请输入' }">
                        <a-input-number v-model:value="desc.first" placeholder="Key:请输入整数" :precision="0" :disabled="isEditing" />
                    </a-form-item>
                    <div class="split-line" style="width: 32px;">:</div>
                    <a-form-item :name="['valueDescription', index, 'last']" :rules="{ required: true, message: '请输入' }"
                        style="width: 144px;">
                        <a-input v-model:value="desc.last" placeholder="枚举说明" />
                    </a-form-item>
                    <a-button style="margin: 0 0 24px 8px;width: 32px;" @click="handleRemove(desc)"
                        v-if="index !== 0 || formState.valueDescription.length > 1">
                        <DeleteOutlined :disabled="formState.valueDescription.length === 1" />
                    </a-button>
                </div>
                <a-form-item class="no-default-split" label=" ">
                    <a-button type="dashed" block @click="handleAdd">
                        <PlusOutlined />
                        添加枚举值
                    </a-button>
                </a-form-item>
            </template>

            <!-- 读写 -->
            <a-form-item v-if="activeTab.value == '02' || activeTab.value == '03'" name="rw" label="读写"
                :rules="{ required: true, message: '请选择是否可读写' }">
                <a-select v-model:value="formState.rw" placeholder="请选择">
                    <a-select-option v-for="item in rwType" :key="item.id" :value="item.value">{{ item.label
                    }}</a-select-option>
                </a-select>
            </a-form-item>
        </a-form>
    </a-modal>
</template>

<script setup>
import { ref, reactive, defineProps, computed, inject, defineEmits, watch } from 'vue'
import { useRoute } from "vue-router";
const route = useRoute();

const emit = defineEmits(['ok', 'cancel'])
const props = defineProps({
    isEditing: {
        type: Boolean,
        default: false,
    },
    tabLabel: String,
    visible: {
        type: Boolean,
        default: false,
    },
    loading: {
        type: Boolean,
        default: false,
    },
    editData: {
        type: Object
    },
    activeTab: {
        type: Object
    },
    codeUniqueFunction: {
    }
})

const title = computed(() => {
    return `${props.isEditing ? '修改' : '添加'}${props.activeTab.label}`
})
watch(() => props.visible, (visible) => {
    if (!visible) {
        formRef.value.resetFields();
        delete formState.id
    }
})
watch(() => props.isEditing, (isEditing) => {
    if (isEditing) {
        formState.id = props.editData.id;
        formState.identifier = props.editData.identifier;
        formState.functionType = props.editData.functionType;
        formState.functionName = props.editData.functionName;
        formState.dataType = props.editData.dataType;
        formState.rw = props.editData.rw;
        switch (props.editData.dataType) {
            case '01':
                formState.stringLimit = props.editData.valueDescription[0].value
                break;
            case '02':
            case '03':
                formState.unit = props.editData.valueDescription.find(e => e.key === 'UNIT')?.value
                formState.valueDescription = [
                    {
                        id: Date.now(),
                        first: props.editData.valueDescription.find(e => e.key === 'MIN')?.value,
                        last: props.editData.valueDescription.find(e => e.key === 'MAX')?.value
                    }
                ]
                break;
            case '04':
                formState.valueDescription = [{
                    id: Date.now(),
                    first: 'True',
                    last: props.editData.valueDescription.find(e => e.key === 'TRUE')?.value,
                }, {
                    id: Date.now(),
                    first: 'False',
                    last: props.editData.valueDescription.find(e => e.key === 'FALSE')?.value,
                }]
                break;
            case '05':
                formState.valueDescription = props.editData.valueDescription.map(e => {
                    return {
                        id: Date.now(),
                        first: e.key,
                        last: e.value
                    }
                })
                break;
            default:
                break;

        }
    }
})

const validateDuplicate = async ({ field }, value) => {
    let m = {
        [field]: value,
        id: formState.id,
        productId: route.params.id
    }
    if (value) {
        let { result } = await props.codeUniqueFunction(m)
        if (!result) {
            return Promise.reject();
        }
    }
    return Promise.resolve();
}

const { functionType, dataType, unit, rwType } = inject('provideParams')

const formLayout = {
    labelCol: {
        style: { width: '88px' }
    },
};

const formState = reactive({
    functionType: undefined,
    identifier: undefined,
    functionName: undefined,
    dataType: undefined,
    stringLimit: undefined,
    unit: undefined,
    valueDescription: [],
    rw: undefined
})
const formRef = ref()
const rulesRef = reactive({
    functionType: [{ required: true, message: '请选择类型' }],
    identifier: [
        { required: true, message: '请输入功能标识' },
        {
            pattern: /^[a-zA-Z][a-zA-Z0-9]*$/,
            message: '功能标识只允许字母、数字组成，首位只能为字母'
        },
        {
            validator: validateDuplicate, message: '功能标识已存在，请输入其他编码'
        }
    ],
    functionName: [{ required: true, message: '请输入功能名称' }],
    dataType: [{ required: true, message: '请选择数据类型' }],
})

const handleAdd = () => {
    formState.valueDescription.push({
        id: Date.now(),
        first: undefined,
        last: undefined,
    })
}
const handleRemove = (desc) => {
    let index = formState.valueDescription.indexOf(desc);
    if (index !== -1) {
        formState.valueDescription.splice(index, 1);
    }
}

const changeDataType = (e) => {
    switch (e) {
        case '01':
            formState.stringLimit = undefined
            break;
        case '04':
            formState.valueDescription = [{
                id: Date.now(),
                first: 'True',
                last: undefined,
            }, {
                id: Date.now(),
                first: 'False',
                last: undefined,
            }]
            break;
        default:
            formState.valueDescription = [{
                id: Date.now(),
                first: undefined,
                last: undefined,
            }]
            break;
    }
}

const ok = () => {
    formRef.value.validateFields().then(() => {
        let m = props.isEditing ? {
            id: formState.id,
            functionName: formState.functionName,
            dataType: formState.dataType,
            rw: formState.rw,
        } : {
            functionType: formState.functionType,
            identifier: formState.identifier,
            functionName: formState.functionName,
            dataType: formState.dataType,
            rw: formState.rw,
            functionCategory: props.activeTab.value
        }
        switch (formState.dataType) {
            case '01':
                m.valueDescription = [{ key: 'LENGTH', value: formState.stringLimit }]
                break;
            case '02':
            case '03':
                m.valueDescription = [
                    { key: 'MIN', value: formState.valueDescription[0].first },
                    { key: 'MAX', value: formState.valueDescription[0].last },
                    { key: 'UNIT', value: formState.unit },
                ]
                break;
            case '04':
                m.valueDescription = [
                    { key: 'TRUE', value: formState.valueDescription[0].last },
                    { key: 'FALSE', value: formState.valueDescription[1].last },
                ]
                break;
            case '05':
                m.valueDescription = formState.valueDescription.map(e => {
                    return {
                        key: e.first,
                        value: e.last,
                    }
                })
                break;
            default:
                break;
        }
        emit('ok', m)
    });

}



</script>
<style lang='less' scoped>
.split-line {
    display: inline-block;
    width: 44px;
    line-height: 32px;
    margin-bottom: 24px;
    text-align: center;
    color: #38404C;
}

:deep .ant-input-number {
    width: 100%;
}

:deep .no-default-split {
    .ant-form-item-label>label::after {
        content: ' ';
    }

    .ant-form-item-required {
        display: none;
    }
}

.flex {
    display: flex;
}

.enums {
    display: flex;
    width: 428px;

    :deep .ant-btn {
        padding: 0;
    }
}
</style>