<template>
    <a-modal :visible="visible" :title="title" @ok="ok" @cancel="emit('cancel')" :confirm-loading="loading" width="540px">
        <a-form ref="formRef" :model="formState" :rules="rulesRef" v-bind="formLayout" style="width: 400px;">
            <a-form-item label="功能类别" required>
                <a-input :value="activeTab.label" disabled />
            </a-form-item>
            <a-form-item name="functionType" label="类型">
                <a-select v-model:value="formState.functionType" placeholder="请选择" :disabled="isEditing">
                    <a-select-option v-for="item in functionType" :key="item.id" :value="item.value">{{ item.label
                    }}</a-select-option>
                </a-select>
            </a-form-item>
            <a-form-item name="identifier" label="功能标识">
                <a-input v-model:value="formState.identifier" placeholder="请输入" :disabled="isEditing"></a-input>
            </a-form-item>
            <a-form-item name="functionName" label="功能名称">
                <a-input v-model:value="formState.functionName" placeholder="请输入"></a-input>
            </a-form-item>

            <a-form-item name="functionParameter" :label="`${activeTab.value === '04' ? '事件' : '服务'}参数`">
                <template v-if="formState.functionParameter.length">
                    <ul>
                        <li class="params-item" v-for="(params, idx) in formState.functionParameter" :key="params.key"
                            @click="itemClick">
                            <div class="ant-input">
                                <div class="content">{{ params.desc }}</div>
                            </div>
                            <a-button @click="handleEdit('functionParameter', params, idx)">
                                <FormOutlined />
                            </a-button>
                            <a-button @click="handleRemove('functionParameter', params)">
                                <DeleteOutlined />
                            </a-button>
                        </li>
                    </ul>
                </template>
                <a-button type="dashed" block @click="handleAdd('functionParameter')">
                    <PlusOutlined />
                    点击添加参数
                </a-button>
            </a-form-item>

            <a-form-item name="responseParameter" label="响应参数">
                <template v-if="formState.responseParameter.length">
                    <ul>
                        <li class="params-item" v-for="(params, idx) in formState.responseParameter" :key="params.key">
                            <div class="ant-input">
                                <div class="content">{{ params.desc }}</div>
                            </div>
                            <a-button @click="handleEdit('responseParameter', params, idx)">
                                <FormOutlined />
                            </a-button>
                            <a-button @click="handleRemove('responseParameter', params)">
                                <DeleteOutlined />
                            </a-button>
                        </li>
                    </ul>
                </template>
                <a-button type="dashed" block @click="handleAdd('responseParameter')">
                    <PlusOutlined />
                    点击添加参数
                </a-button>
            </a-form-item>
        </a-form>
    </a-modal>
    <modalEventParams :isEditing="isEditing" :visible="visible2" :loading="false" :editData="editParams"
        :active-tab="activeTab" @ok="ok2" @cancel="cancel2" :paramsType="paramsType" :existParamsIds="existParamsIds" />
</template>

<script setup>
import { ref, reactive, defineProps, computed, inject, defineEmits, watch, nextTick } from 'vue'

import { useRoute } from "vue-router";
const route = useRoute();

import modalEventParams from './modalEventParams.vue';
const emit = defineEmits(['ok', 'cancel'])
const props = defineProps({
    isEditing: {
        type: Boolean,
        default: false,
    },
    tabLabel: String,
    visible: {
        type: Boolean,
        default: false,
    },
    loading: {
        type: Boolean,
        default: false,
    },
    editData: {
        type: Object
    },
    activeTab: {
        type: Object
    },
    codeUniqueFunction: {
    }
})

const title = computed(() => {
    return `${props.isEditing ? '修改' : '添加'}${props.activeTab?.label}`
})
const paramsKey = ref('')
// 当前活动的是哪个参数Key，是事件key：functionParameter 还是响应key：responseParameter
const paramsType = ref('')

watch(() => props.visible, (visible) => {
    if (!visible) {
        formRef.value.resetFields();
        delete formState.id
    }
})
watch(() => props.isEditing, (isEditing) => {
    if (isEditing) {
        formState.id = props.editData.id;
        formState.identifier = props.editData.identifier;
        formState.functionType = props.editData.functionType;
        formState.functionName = props.editData.functionName;
        formState.functionParameter = genDesc(props.editData.functionParameter, props.activeTab.value === '05' ? '服务' : '事件');
        formState.responseParameter = genDesc(props.editData.responseParameter, '响应');
    }
})

const existParamsIds = ref([])

const validateDuplicate = async ({ field }, value) => {
    let m = {
        [field]: value,
        id: formState.id,
        productId: route.params.id
    }
    if (value) {
        let { result } = await props.codeUniqueFunction(m)
        if (!result) {
            return Promise.reject();
        }
    }
    return Promise.resolve();
}

const { functionType, dataType } = inject('provideParams')
const formLayout = {
    labelCol: {
        style: { width: '100px' }
    },
};

const formState = reactive({
    functionType: undefined,
    identifier: undefined,
    functionName: undefined,
    functionParameter: [],
    responseParameter: [],
})
const formRef = ref()
const rulesRef = reactive({
    functionType: [{ required: true, message: '请选择类型' }],
    identifier: [
        { required: true, message: '请输入功能标识' },
        {
            pattern: /^[a-zA-Z][a-zA-Z0-9]*$/,
            message: '功能标识只允许字母、数字组成，首位只能为字母'
        },
        {
            validator: validateDuplicate, message: '功能标识已存在，请输入其他编码'
        }
    ],
    functionName: [{ required: true, message: '请输入功能名称' }],
})

const visible2 = ref(false)
const editParams = ref()

const genDesc = (params, pre) => {
    if (params) {
        return params.map(m => {
            let dataTypeContent = dataType.value.find(e => e.value === m.dataType).label;
            m.desc = `${pre}标识：${m.identifier}；${pre}名称：${m.name}；${pre}数据类型：${dataTypeContent}；${pre}描述：`
            switch (m.dataType) {
                case '01':
                    m.desc += m.valueDescription.find(e => e.key === 'LENGTH').value
                    break;
                case '02':
                case '03':
                    m.desc += `${m.valueDescription[0].first}-${m.valueDescription[0].last}；${pre}单位：${m.unit}`
                    break;
                case '04':
                    m.desc += `TRUE-${m.valueDescription[0].first}；FALSE-${m.valueDescription[1].last}`
                    break;
                case '05':
                    m.desc += m.valueDescription.map(e => `${e.first}-${e.last}`).join('；')
                    break;
                default:
                    break;
            }
            return {
                ...m
            }
        });
    }
    return []
}

const ok = () => {
    formRef.value.validateFields().then(() => {
        let m = props.isEditing ? {
            id: formState.id,
            functionName: formState.functionName,
            responseParameter: formState.responseParameter,
            functionParameter: formState.functionParameter,
        } : {
            functionCategory: props.activeTab.value,
            ...formState
        }
        console.log(m);
        emit('ok', m)
    });
}

const ok2 = (m) => {
    visible2.value = false;
    nextTick(() => {
        let idx = existParamsIds.value.findIndex(identifier => identifier === m.identifier)
        if (idx === -1) {
            let newM = genDesc([m], props.activeTab.value === '05' ? '服务' : '事件')[0]
            formState[paramsKey.value].push(newM)
        } else {
            let newM = genDesc([m], '响应')[0]
            formState[paramsKey.value].splice(idx, 1, newM);
        }
    })
}

const cancel2 = () => {
    visible2.value = false;
}


const updateExistParamsIds = () => {
    existParamsIds.value = formState[paramsKey.value].map(e => e.identifier)
}
const updateParamsType = (paramsKey) => {
    if (paramsKey === 'responseParameter') {
        paramsType.value = '响应';

    } else if (props.activeTab.value === '05') {
        paramsType.value = '服务';
    } else {
        paramsType.value = '事件';
    }
}
const handleAdd = (key) => {
    updateParamsType(key)
    paramsKey.value = key
    editParams.value = {}
    visible2.value = true
    updateExistParamsIds()

}
const handleEdit = (key, params, idx) => {
    console.log('handleEdit');
    updateParamsType(key)
    paramsKey.value = key
    visible2.value = true;
    params.idx = idx
    editParams.value = params
    updateExistParamsIds()

}
const handleRemove = (key, params) => {
    console.log('handleRemove');
    let index = formState[key].indexOf(params);
    if (index !== -1) {
        formState[key].splice(index, 1);
    }
}
const itemClick = () => {
    console.log(888888, 'itemClick')
}



</script>
<style lang='less' scoped>
.split-line {
    display: inline-block;
    width: 44px;
    line-height: 32px;
    margin-bottom: 24px;
    text-align: center;
    color: #38404C;
}

:deep .ant-input-number {
    width: 100%;
}

:deep .no-default-split {
    .ant-form-item-label>label::after {
        content: ' ';
    }

    .ant-form-item-required {
        display: none;
    }
}

.flex {
    display: flex;
}

.enums {
    display: flex;
    width: 428px;

    :deep .ant-btn {
        padding: 0;
    }
}

.params-item {
    display: flex;
    text-align: center;
    width: 380px;

    :deep .ant-btn {
        padding: 0;
        margin: 0 0 24px 8px;
        width: 32px;
    }

    & .ant-input {
        flex: 1;
        height: 32px;
        overflow-x: auto;
        white-space: nowrap;

        & .content {
            overflow-x: auto;
            -ms-overflow-style: none;
            overflow: -moz-scrollbars-none;

            &::-webkit-scrollbar {
                display: none;
            }
        }
    }

}
</style>