<!-- 设备事件、设备服务的事件参数和响应参数共用弹窗 -->
<template>
    <a-modal :visible="visible" :title="title" @ok="ok" @cancel="emit('cancel')" :confirm-loading="loading" width="500px">
        <a-form ref="formRef" :model="formState" :rules="rulesRef" v-bind="formLayout" style="width: 428px;">
            <a-form-item name="identifier" :label="`${paramsType}标识`">
                <a-input v-model:value="formState.identifier" placeholder="请输入" :disabled="isEditing"></a-input>
            </a-form-item>
            <a-form-item name="name" :label="`${paramsType}名称`">
                <a-input v-model:value="formState.name" placeholder="请输入"></a-input>
            </a-form-item>
            <a-form-item name="dataType" :label="`${paramsType}数据类型`">
                <a-select v-model:value="formState.dataType" @change="changeDataType" placeholder="请选择"
                    :disabled="isEditing">
                    <a-select-option v-for="item in dataType" :key="item.id" :value="item.value">{{ item.label
                    }}</a-select-option>
                </a-select>
            </a-form-item>

            <!-- 根据数据类型 展示不同数据 -->
            <a-form-item v-if="formState.dataType == '01'" name="stringLimit" label="字符数量"
                :rules="{ required: true, message: '请输入字符数量' }">
                <a-input-number v-model:value="formState.stringLimit" :min="0" />
            </a-form-item>
            <template v-else-if="formState.dataType == '02' || formState.dataType == '03'">
                <a-space v-for="(desc, index) in formState.valueDescription" :key="desc.id">
                    <a-form-item :name="['valueDescription', index, 'first']" label="数值范围"
                        :rules="{ required: true, message: '请输入最小值' }">
                        <a-input-number v-model:value="desc.first" placeholder="最小值" :max="desc.last - 1"
                            :precision="formState.dataType == '02' ? 0 : undefined" />
                    </a-form-item>
                    <div class="split-line">—</div>
                    <a-form-item :name="['valueDescription', index, 'last']" :rules="{ required: true, message: '请输入最大值' }">
                        <a-input-number v-model:value="desc.last" placeholder="最大值" :min="desc.first + 1"
                            :precision="formState.dataType == '02' ? 0 : undefined" />
                    </a-form-item>
                </a-space>
                <a-form-item name="unit" label="单位" :rules="{ required: true, message: '请选择单位' }">
                    <a-select v-model:value="formState.unit" placeholder="请选择单位">
                        <a-select-option v-for="item in unit" :key="item.id" :value="item.value">{{ item.label
                        }}</a-select-option>
                    </a-select>
                </a-form-item>
            </template>
            <template v-else-if="formState.dataType == '04'">
                <a-space v-for="(desc, index) in formState.valueDescription" :key="desc.id">
                    <a-form-item :class="index === 0 ? '' : 'no-default-split'" :name="['valueDescription', index, 'first']"
                        :label="index === 0 ? '布尔值' : ' '" :rules="{ required: true, message: '请输入' }">
                        <a-input :value="desc.first" disabled />
                    </a-form-item>
                    <div class="split-line" style="width: 32px;">:</div>
                    <a-form-item :name="['valueDescription', index, 'last']" :rules="{ required: true, message: '请输入' }">
                        <a-input v-model:value="desc.last" placeholder="请输入" />
                    </a-form-item>
                </a-space>
            </template>
            <template v-else-if="formState.dataType == '05'">
                <div class="enums" v-for="(desc, index) in formState.valueDescription" :key="desc.id">
                    <a-form-item :class="index === 0 ? '' : 'no-default-split'" :name="['valueDescription', index, 'first']"
                        style="width: 242px;" :label="index === 0 ? '枚举值' : ' '"
                        :rules="{ required: true, message: '请输入' }">
                        <a-input-number v-model:value="desc.first" placeholder="Key:请输入整数" :precision="0" :disabled="isEditing" />
                    </a-form-item>
                    <div class="split-line" style="width: 32px;">:</div>
                    <a-form-item :name="['valueDescription', index, 'last']" :rules="{ required: true, message: '请输入' }"
                        style="width: 144px;">
                        <a-input v-model:value="desc.last" placeholder="枚举说明" />
                    </a-form-item>
                    <a-button v-if="index !== 0 || formState.valueDescription.length > 1">
                        <DeleteOutlined :disabled="formState.valueDescription.length === 1" @click="removeDesc(desc)" />
                    </a-button>
                </div>
                <a-form-item class="no-default-split" label=" ">
                    <a-button type="dashed" block @click="addDesc">
                        <PlusOutlined />
                        添加枚举值
                    </a-button>
                </a-form-item>
            </template>

        </a-form>
    </a-modal>
</template>

<script setup>
import { ref, reactive, defineProps, computed, inject, defineEmits, watch } from 'vue'

const emit = defineEmits(['ok', 'cancel'])
const props = defineProps({
    tabLabel: String,
    visible: {
        type: Boolean,
        default: false,
    },
    loading: {
        type: Boolean,
        default: false,
    },
    editData: {
        type: Object
    },
    activeTab: {
        type: Object
    },
    paramsType: {
        type: String
    },
    existParamsIds: {
        type: Array
    }
})

const title = computed(() => {
    return `${props.isEditing ? '修改' : '添加'}${props.paramsType}参数`
})
const isEditing = computed(() => {
    return props.editData?.idx !== undefined
})

const { dataType, unit } = inject('provideParams')

// watch(() => props.visible, (visible) => {
//     if (!visible) {
//         formRef.value.resetFields();
//     } 
// })
watch(() => props.editData, (editData) => {
    formState.identifier = editData.identifier;
    formState.name = editData.name;
    formState.dataType = editData.dataType;
    switch (editData.dataType) {
        case '01':
            formState.stringLimit = editData.valueDescription[0].value
            break;
        case '02':
        case '03':
            formState.unit = editData.valueDescription.find(e => e.key === 'UNIT')?.value
            formState.valueDescription = [
                {
                    id: Date.now(),
                    first: editData.valueDescription.find(e => e.key === 'MIN')?.value,
                    last: editData.valueDescription.find(e => e.key === 'MAX')?.value
                }
            ]
            break;
        case '04':
            formState.valueDescription = [{
                id: Date.now(),
                first: 'True',
                last: editData.valueDescription.find(e => e.key === 'TRUE')?.value,
            }, {
                id: Date.now(),
                first: 'False',
                last: editData.valueDescription.find(e => e.key === 'FALSE')?.value,
            }]
            break;
        case '05':
            formState.valueDescription = editData.valueDescription.map(e => {
                return {
                    id: Date.now(),
                    first: e.key,
                    last: e.value
                }
            })
            break;
        default:
            break;

    }
})

const validateDuplicate = ({ field }, value) => {
    console.log(field);
    if (isEditing.value) {
        return Promise.resolve()
    }
    let idx = props.existParamsIds.findIndex(id => id === value)
    return idx === -1 ? Promise.resolve() : Promise.reject();
}

const formLayout = {
    labelCol: {
        style: { width: '108px' }
    },
};


const formState = reactive({
    identifier: undefined,
    name: undefined,
    dataType: undefined,
    stringLimit: undefined,
    unit: undefined,
    valueDescription: [],
})
const formRef = ref()
const rulesRef = reactive({
    identifier: [
        { required: true, message: '请输入功能标识' },
        {
            pattern: /^[a-zA-Z][a-zA-Z0-9]*$/,
            message: '功能标识只允许字母、数字组成，首位只能为字母'
        },
        {
            validator: validateDuplicate, message: '功能标识已存在，请输入其他编码'
        }
    ],
    name: [{ required: true, message: '请输入功能名称' }],
    dataType: [{ required: true, message: '请选择数据类型' }],
})

const addDesc = () => {
    formState.valueDescription.push({
        id: Date.now(),
        first: undefined,
        last: undefined,
    })
}
const removeDesc = (desc) => {
    let index = formState.valueDescription.indexOf(desc);
    if (index !== -1) {
        formState.valueDescription.splice(index, 1);
    }
}

const changeDataType = (e) => {
    switch (e) {
        case '01':
            formState.stringLimit = undefined
            break;
        case '04':
            formState.valueDescription = [{
                id: Date.now(),
                first: 'True',
                last: undefined,
            }, {
                id: Date.now(),
                first: 'False',
                last: undefined,
            }]
            break;
        default:
            formState.valueDescription = [{
                id: Date.now(),
                first: undefined,
                last: undefined,
            }]
            break;
    }
}

const ok = () => {
    formRef.value.validateFields().then(() => {
        let m = {
            identifier: formState.identifier,
            name: formState.name,
            dataType: formState.dataType,
        }
        switch (formState.dataType) {
            case '01':
                m.valueDescription = [{ key: 'LENGTH', value: formState.stringLimit }]
                break;
            case '02':
            case '03':
                m.valueDescription = [
                    { key: 'MIN', value: formState.valueDescription[0].first },
                    { key: 'MAX', value: formState.valueDescription[0].last },
                    { key: 'UNIT', value: formState.unit },
                ]
                break;
            case '04':
                m.valueDescription = [
                    { key: 'TRUE', value: formState.valueDescription[0].last },
                    { key: 'FALSE', value: formState.valueDescription[1].last },
                ]
                break;
            case '05':
                m.valueDescription = formState.valueDescription.map(e => {
                    return {
                        key: e.first,
                        value: e.last,
                    }
                })
                break;
            default:
                break;
        }
        console.log(m);
        emit('ok', m)
    });

}



</script>
<style lang='less' scoped>
.split-line {
    display: inline-block;
    width: 44px;
    line-height: 32px;
    margin-bottom: 24px;
    text-align: center;
    color: #38404C;
}

:deep .ant-input-number {
    width: 100%;
}

:deep .no-default-split {
    .ant-form-item-label>label::after {
        content: ' ';
    }

    .ant-form-item-required {
        display: none;
    }
}

.flex {
    display: flex;
}

.enums {
    display: flex;
    width: 468px;

    :deep .ant-btn {
        padding: 0;
        margin: 0 0 24px 8px;
        width: 32px;
    }
}
</style>